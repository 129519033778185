@use "@material/theme" with (
  $primary: #212121,
  $secondary: #c62828,
  $on-primary: #ffffff,
  $on-secondary: #ffffff,
);

// Body
$body-bg: #9e9e9e;

// Typography
$font-family-sans-serif: 'Roboto';
$font-size-base: 0.9rem;
$line-height-base: 1.6;