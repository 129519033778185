@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '../../sass/variables';
@import '~bootstrap/scss/bootstrap';
@import "material-components-web/material-components-web";

#loginForm img {
    object-fit: scale-down;
    max-width: 300px;
}

.mdc-text-field {
    width: 300px;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #9E9E9E inset;
  transition: background-color 5000s ease-in-out 0s;
}